@import '../../Styles/Mixin.scss';

#nova-campanha {
    .steps {
        display: flex;
        height: 179px;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .item {
            background: rgba(#fff, 0.7);
            border-radius: 5px;
            padding: 20px;
            position: relative;
            display: flex;
            align-items: center;
            box-shadow: $boxShadow;
            transition: all 0.5s;
            &.active {
                background: rgba(#fff, 1);
                .icon {
                    background-color: #29D391;
                    svg {
                        * {
                            fill: #FFF;
                        }
                    }
                }
            }
            b {
                white-space: nowrap;
            }
            .icon {
                background: rgba($colorPrimary, 0.1);
                width: 50px;
                height: 50px;
                border-radius: 50px;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    font-size: 20px;
                    width: 20px;
                    height: 20px;
                    * {
                        fill: black;
                    }
                }
            }
            i {
                position: absolute;
                top: 0;
                right: 0;
                font-style: normal;
                margin: 10px;
                font-size: 11px;
            }
            b {
                color: rgba(#000, 0.6);
            }
        }
    }
    > .form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        align-items: center;
        margin-bottom: 60px;
        .form-data {
            width: 600px;
            padding-top: 50px;
            > .info {
                margin: 0px;
                padding: 0px;
                background-color: rgba(0,0,0,.1);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                list-style: none;
                padding: 20px;
                box-sizing: border-box;
                li {
                    font-size: 13px;
                    a {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
        .col {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        .row {
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;
        }
        
        .canais {
            button {
                display: flex;
                flex-direction: column;
                padding: 30px;
                border-radius: 5px;
                justify-content: center;
                align-items: center;
                height: 130px;
                width: 130px;
                cursor: pointer;
                border: none;
                background-color: rgba(0,0,0,.04);
                span {
                    margin-top: 15px;
                }
                &.active {
                    background-color: #29D391;
                    animation: pulse .5s;
                }
            }
        }
        .base-dados {
            .input-proccess {
                .button-default {
                    margin-top: 20px;
                }
            }
        }
        .georeferencia {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .geo-maps {
                margin-top: 40px;
                width: 100%;
                height: 600px;
                box-sizing: border-box;
                border-radius: 5px;
                position: relative;
                overflow: hidden;
                border-radius: 5px;
            }
            .filters {
                display: flex;
                align-items: flex-end;
                gap: 10px;
                justify-content: flex-start;
                width: 100%;
                padding: 20px;
                box-sizing: border-box;
                background: rgba(0,0,0,.2);
                backdrop-filter: blur(5px);
                position: absolute;
                top: 0px;
                z-index: 9999;
                
            }
            .list-address {
                width: 400px;
                padding: 20px;
                box-sizing: border-box;
                gap: 10px;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 105px;
                margin: 0px;
                padding: 0px;
                z-index: 999;
                background: rgba(255,255,255,.5);
                height: calc(100% - 105px);
                backdrop-filter: blur(5px);
                box-sizing: border-box;
                padding: 15px;
                overflow-x:auto;
                list-style: none;
                fieldset {
                    border-radius: 5px;
                    border: 1px solid rgba(0,0,0,.1);
                    background: #FFF;
                    div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        span {
                            font-size: 12px
                        }
                        button {
                            background: #f75676;
                            border: none;
                            color: #FFF;
                            border-radius: 100px;
                            cursor: pointer;
                            padding: 5px 10px;
                            font-size: 12px;
                        }
                    }
                }
            }
            .maps {
                width: 100%;
                height: 100%;
            }
        }
    }
    .buttons {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 60px;
        gap: 20px;
    }
}