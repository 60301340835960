@import '../../Styles/Mixin.scss';
.posts {
    column-count: 4;
    @media (max-width: 1920px) {
        column-count: 3;
    }
    column-gap: 25px;
}
.box-post {
    display: inline-block;
    width: 100%;
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 25px;
    border-radius: 4px;
    box-shadow: $boxShadow;
    font-size: 13px;
    color: #333;
    position: relative;
    .icon {
        width: 40px;
        height: 40px;
        border-radius: 100px;
        position: absolute;
        right: 0px;
        top: 0px;
        box-shadow: $boxShadow;
        margin: 10px;
        background: #eaeaea;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .head-info {
        display: flex;
        align-items: center;
        .photo {
            width: 45px;
            height: 45px;
            border-radius: 100px;
            background-size: cover;
            background-color: #eaeaea;
        }
        .infos {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-left: 10px;
        }
    }
    .image {
        width: 100%;
        display: flex;
        margin-top: 15px;
        img {
            display: block;
            max-width: 100%;
            border-radius: 5px;
        }
    }
    .text {
        padding: 10px 0px;
    }
    .extra-info {
        display: flex;
        flex-direction: column;
        a {
            color: $colorInfo;
        }
        div,
        a {
            display: flex;
            align-items: center;
            svg {
                margin-right: 4px;
            }
            span {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 100%;
            }
        }
    }
    .mentions {
        background: red;
    }
    .tags {
        padding: 15px 0 0 0;
        a {
            display: inline-block;
            padding: 0px 5px 5px 0;
            color: $colorPrimary;
        }
    }
    .popularity {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
        > div {
            background: rgba(#000, 0.05);
            padding: 10px 12px;
            border-radius: 3px;
            margin-right: 5px;
            span {
            }
            b {
                padding-left: 5px;
            }
        }
    }
    .sentiment {
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        overflow: hidden;
        margin-top: 20px;
        > div {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0px;
            font-size: 30px;
            background: rgba(#000, 0.05);
            &.positive {
                background: $colorSuccess;
                color: #fff;
            }
            &.neutral {
                background: $colorInfo;
                color: #fff;
            }
            &.negative {
                background: $colorDanger;
                color: #fff;
            }
        }
    }
}
