@import '../../Styles/Mixin.scss';

#RecomecaRJ {
    #chart {
        width: 100%;
        height: 259px;
        padding-top: 20px;
        box-sizing: border-box;
        padding-bottom: 20px;
        text {
            font-size: 13px;
        }
        .recharts-cartesian-axis-ticks {
            padding-top: 20px;
            margin-bottom: -10px;
        }
    }
    .table-default {
        margin-top: 40px;
    }

    .modal-overlay {
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.5s;
        z-index: 999999;
        &.active {
            opacity: 1;
            visibility: visible;
        }
        .close {
            position: absolute;
            right: 0;
            top: 0;
            margin: 40px;
            background: none;
            font-size: 40px;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            z-index: 9999999;
        }
        .img {
            max-width: 90vw;
            max-height: 90vh;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            img {
                transform: scalex(-1);
            }
            h3 {
                color:#FFF;
            }
            @media(max-width: 600px){
                overflow: auto;
                justify-content: unset;
                img {
                    max-height: 80vh;
                }
            }
        }
    }
}
