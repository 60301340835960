@import '../../Styles/Mixin.scss';

#monitoramento-social-salvo {
    .header-back {
        display: flex;
        justify-content: flex-start;
        .goback {
            border: none;
            background: transparent;
            display: flex;
            cursor: pointer;
            margin: 0px;
            padding: 10px 15px;
            background: rgba(255, 255, 255, 0.5);
            margin-top: 15px;
            font-size: 13px;
            text-transform: uppercase;
            color: $colorBlack;
            border-radius: 3px;
            align-items: center;
        }
    }
    .loading-container {
        background: #fff;
        display: flex;
        width: 100%;
        justify-content: center;
        max-height: calc(100vh - 270px);
        border-radius: 5px;
        img {
            width: 50%;
        }
    }
    .filter {
        display: flex;
        margin-bottom: 20px;
        border-bottom: 2px solid rgba(#000, 0.1);
        button {
            background: transparent;
            border: none;
            outline: none;
            padding: 10px 20px;
            margin-bottom: -2px;
            cursor: pointer;
            border-bottom: 2px solid transparent;
            &.active {
                border-bottom: 2px solid $colorPrimary;
                color: #666;
            }
        }
    }
    .search-social {
        display: flex;
        flex-direction: column;
        padding: 15px 0px;
        h4 {
            font-size: 12px;
            margin: 0px;
            color: rgba(255, 255, 255, 0.5);
            font-weight: normal;
            text-transform: uppercase;
        }
        .social-list {
            padding: 10px 0px;
            display: flex;
            button {
                background-color: rgba(#fff, 0.8);
                height: 45px;
                min-width: 45px;
                max-width: unset !important;
                width: unset !important;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: none;
                margin-right: 7px;
                box-shadow: 0 1px 3px rgba(50, 50, 93, 0.1),
                    0 1px 0 rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                svg {
                    width: 30px;
                    height: 18px;
                    path,
                    g {
                        fill: rgba(#000, 0.2);
                    }
                }
                span {
                    font-size: 12px;
                    color: rgba(#000, 0.2);
                    margin-right: 10px;
                }
                &.active {
                    background-color: $colorSuccess;
                    path,
                    g {
                        fill: #fff;
                    }
                    span {
                        color: #fff;
                    }
                }
            }
        }
        .sentiment-list {
            padding: 10px 0px;
            display: flex;
            button {
                background-color: rgba(#fff, 0.8);
                height: 45px;
                min-width: 45px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: none;
                box-shadow: 0 1px 3px rgba(50, 50, 93, 0.1),
                    0 1px 0 rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                margin-right: 6px;
                svg {
                    width: 30px;
                    height: 18px;
                    path,
                    g {
                        fill: rgba(#000, 0.8);
                    }
                }

                span {
                    font-size: 12px;
                    color: rgba(#000, 0.8);
                    margin-right: 10px;
                }
                &.active {
                    background-color: #333;
                    svg {
                        path,
                        circle {
                            fill: white;
                        }
                    }
                    span {
                        color: #fff;
                    }
                }
            }
        }
        .input-container {
            flex: 1;
            display: flex;
            align-items: center;
            width: calc(100% + 10px);
            margin: 0px -10px;
            .btn-icon {
                min-height: 45px;
                height: 45px;
                color: #000;
                background-color: $colorInfo;
                padding: 0px 15px;
                font-size: 20px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0 1px 3px rgba(50, 50, 93, 0.1),
                    0 1px 0 rgba(0, 0, 0, 0.1);
                border: 0;
                border-radius: 4px;
                outline: none;
                cursor: pointer;
            }
        }
    }
    .title-post {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        svg {
            color: $colorBlack;
        }
        h3 {
            padding: 2px 0 0 5px;
            margin: 0px;
            color: $colorBlack;
        }
    }
    @media print {
        .input-container {
            .button-default {
                display: none;
            }
        }
        .search-social {
            display: none !important;
        }
    }
}
