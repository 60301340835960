.linha-do-tempo {
    .data {
        width: 100%;
        height: 400px;
        background-color: #fff;
        padding: 50px 10px 30px 0px;
        box-sizing: border-box;
        border-radius: 5px;
    }
}
